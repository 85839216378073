.tinynav{
	display:none;
	text-align:center;
	width:100%;
	@include breakpoint(allmobile){
		display:block;
	}
}

#mobile-menu{
	padding:80px 0 0;
	@extend .tinynav;	
	width:100%;
	text-align:center;
}

#utility-container{
	position:relative;
	background-color:$palecyan;
	font-family: $heading_font;
	ul{
		margin:0;
		padding:10px 0;
		text-align:right;
		@include breakpoint(allmobile){
			width:100%;
			text-align:center;
		}
	}
	li{
		display:inline;
		border-left:1px solid $dark_blue;
		padding:0 15px;
		&:first-child{
			border:0 !important;
		}
		&:last-child{
			padding-right:0;
		}
	}
	a{
		padding-top:10px;
		font-size:12px;
		color:$dark_blue;
		&:hover{
			color:$lightred;
		}
	}
	.menu{
		float:right;
	}
	.social-menu{
		float:right;
		padding-left:10px;
		li{
			padding:0 3px;
			border:0;
			a{
				font-size:22px;
			}
		}
	}
}

#main-nav-container{
	position: relative;
	background-color:$lightpalecyan;
	font-family: $heading_font;
	z-index: 9999;
	nav{
		border-bottom:16px solid $lightred;

	}
	ul{
		margin:0;
		padding:0;
	}
	ul[id*="left"]{
		float:left;
		width:49%;
		text-align:center;
		ul{
			display:none;
		}
		@include breakpoint(phablet){
			width:40%;
		}
	}
	ul[id*="right"]{
		float:right;
		width:49%;		
		text-align:center;
		ul{
			display:none;
		}
		@include breakpoint(phablet){
			width:40%;
		}
	}
	li{
		position: relative;
		display:inline-block;
		width:140px;		
		margin:0;
		padding:0;
		a{
			display:block;
			padding:33px 15px 25px;
			font-size:16px;
			text-transform: uppercase;
			color:$dark_blue;
			&:hover{
				color:$lightred;
			}
		}
		&.current-menu-item{
			a{
				color:$lightred;
			}
		}
		&.current_page_ancestor{
			a{
				color:$lightred;
			}			
		}
		&.menu-item-has-children{
			a{
				&:after{
				    content: "\f078";
				    font-family: FontAwesome;
				    font-style: normal;
				    font-weight: normal;
				    text-decoration: inherit;
				    font-size: 13px;
				    padding-left: 0.5em;
				    right: 0;
				}
			}	
			li{
				a{
					&:after{
						content:none;
					}
				}
			}		
		}
		&:hover{
			ul{
				display:block;
				margin:0;
				padding:10px 0;
				position: absolute;
				background-color:$dark_blue;
				left:25px;
				z-index: 9999;
				width:300px;
			}				
			li{
				margin:0;
				text-align: left;
				width:300px;
				a{
					padding:2px 15px;
					display:block;
					color:$lightpalecyan;
					font-size:12px;
					&:before{
					    content: "\f005";
					    font-family: FontAwesome;
					    font-style: normal;
					    font-weight: normal;
					    text-decoration: inherit;
					    font-size: 10px;
					    padding-right: 0.5em;
					    top: 10px;
					    left: 0;
					}
					&:hover{
						color:white;
					}
				}
			}
		}
		@include breakpoint(phablet){
			width:110px;

		}
		@include breakpoint(allmobile){
			display:none;
		}
	}
	.logo{
		position: absolute;
		left: 50%;
		margin: -48px 0 0 -72px;
		padding-top:20px;
		@include breakpoint(tablet){
			margin: -28px 0 0 -42px;
			img{
				width:75%;

			}
		}
		@include breakpoint(phablet){
			margin: -28px 0 0 -42px;
			img{
				width:75%;

			}
		}
		@include breakpoint(allmobile){
			margin: -10px 0 0 -32px;
			img{
				width:50%;

			}
		}
	}
}

.post-type-archive-tribe_events{
	.menu-item-38{
		a{
			color: $lightred !important;
		}
	}
}
// #General body style
body {
  background: $dark_blue;
  font: 14px/21px $body_font_family;
  color: $darkgray;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

// #General horizontal rule style
hr {
  border: $hr_border;
  border-width: $hr_border_width;
  clear: both;
  margin: $hr_margin;
  height: 0;
}

.alignleft{
	float:left;
	padding-right:10px;
	padding-bottom:10px;
}

.alignright{
	float:right;
	padding-left:10px;	
	padding-bottom:10px;
}

.big-container{
	width:100%;
	&.pages{
		padding-top:54px;
	}
}

.home{
	nav{
		border-bottom:0 !important;
		z-index: 9999;
	}
}

/* Main Container styles */

#main-content-container{
	@include vertical-gradient($lightpalecyan,#fff);
}

/* Hero */

#hero-container{
	background-color:$lightpalecyan;
	.hero{
		display:none;
		position:relative;
		min-height:450px;
		padding-top:0px;
		background-size:1300px 450px;
		background-position: center center;
		background-repeat:no-repeat;
 	}
 	.hero-message{
	 	padding-top:200px;
	 	color:#fff;
	 	text-align:center;
	 	@include breakpoint(allmobile){
		 	padding-top:140px;
	 	}
	 	h1{
		 	font-size:69px;
		 	color:#fff;
		 	@include breakpoint(allmobile){
			 	font-size:45px;
		 	}
	 	}
 	}
}

#small-callout{
	background-color:$lightpalecyan;	
	text-align:center;
	h2{
		margin:0;
		padding:25px 0 25px;
		font-size:14px;
		line-height:18px;
		color:$dark_blue;
	}
	span{
		padding:0 10px;
	 	@include breakpoint(allmobile){
	 		display:block;
	 		padding:10px 0;
	 	}
	}
}

/* Main content area */

#main-content{
	background-color:#fff;
	border-top:3px solid $dark_blue;
	h1{
		margin:30px 30px 15px;
		padding-bottom:15px;
		font-size:18px;
		line-height:20px;
		color:$dark_blue;
		text-transform: uppercase;
		border-bottom:1px solid #ccc;
	}
	h2{
		margin-top:20px;
		font-size:28px;
		line-height:32px;
	}
	a{
		color:$dark_blue;
		&:hover{
			color:$lightred;
		}
	}
	.news-thumb{
		margin:0;
		padding:0;
		text-align:center;
		img{
			margin:15px 15px 15px 30px;
			@include breakpoint(allmobile){
				width:83%;
				text-align:center;
				margin:0;
			}
			@include breakpoint(tablet){
				width:80%;
				padding:15px 0 15px 0;
			}

		}
	}
	.post{
		margin:0 30px;
		padding:2px 0;
		border-top:1px solid $lightgray !important;
		.entry-content{
			margin-top:12px;
		}
		h1{
			margin-left:0;
			margin-bottom:5px;
			font-size:28px;
			line-height:32px;
			color:$dark_blue;
		}
		h2,h3{
			margin-left:0 !important;
		}
	}
	.row{
		.four{
			&.columns{
				.post{
					border:0 !important;
				}
			}
			&.omega{
				.post{
					margin-left:0;
					@include breakpoint(allmobile){
						margin-left:30px;
					}
				}
			}
		}
		.five{
			&.columns{
				.post{
					border:0 !important;
				}
			}

		}
		
	}
	.intro-post{
		@extend .post;
		border:0;
	}
	.entry-meta{
		font-size:12px;
		font-weight:bold;
	}
	.entry-content{
		margin-top:30px;
		font-size:14px;
		h1{
		}
		h2{
			font-size:28px;
			color:$dark_blue;
			margin:0 0 15px 30px;
		}
		h3{
			font-size:18px;
			text-transform:uppercase;			
			line-height:22px;
			margin-left:30px;
			color:$dark_blue;
		}
		a{
			background-color:$lightpalecyan;
		}		

		.row{
			hr{
				margin:0 30px;
			}
			&.full-width-content{
				p{
					margin:0 30px 20px;
				}
			}
			&.two-column-content{
				.column_1{
					p{
						margin:0 0 20px 30px;
					}			
				}
				.column_2{
					p{
						margin:0 30px 20px 0;
					}								
				}
			}
			&.two-column-small-left{
				.column_1{
					p{
						margin:0 0 20px 30px;
					}			
				}
				.column_2{
					p{
						margin:0 30px 20px 0;
					}								
				}				
			}
			&.two-column-small-right{
				.column_1{
					p{
						margin:0 0 20px 30px;
					}			
				}
				.column_2{
					p{
						margin:0 30px 20px 0;
					}								
				}								
			}
			&.image-content-area{
				img{
					margin:0 30px;
				}
			}
			&.gallery-content-area{
				ul{
					margin:0 30px;
					padding:0;
				}
				li{
					display:inline-block;
				}
			}
		}
		.column_2{
			h1,h2,h3,h4,h5{
				margin:0;
			}
		}
	}
	.read-more{
		margin-top:15px;
	}
	.featured-image{
		padding:150px 0 24px;
		background-color:$dark_blue;
		background-position:center center;
		background-size:cover;
		background-repeat: no-repeat;		
		h1{
			margin:0 0 0 30px;
			padding:0;
		 	font-size:69px;
		 	line-height:72px;
		 	color:#fff;
		 	text-transform: uppercase;
		 	border:0 !important;
		 	@include breakpoint(allmobile){
			 	margin:0;
			 	font-size:48px;
			 	text-align:center;
		 	}
		}
	}	
}

.photo-credit{
	padding:2px 20px;
	float:right;
	color:rgb(172,173,173);
	font-style: italic;
	font-size:10px;
	&.home{
		float:none;
	}
}
.photo-credit-link{
	width:340px;
	position: absolute;
	bottom:20px;	
	right:35%;
	left:65%;
	text-align: right;
	span{
		color:#fff !important;
	}
 	@include breakpoint(allmobile){
 		display:none;
 	}
}



.single{
	h1{
		border:0 !important;
	}
}


/* Sidebar content area */

#sidebar{
	h3{
		padding-bottom:5px;
		font-size:11px;
		font-weight:bold;
		color:$dark_blue;		
		line-height:normal;
		text-transform: uppercase;
	}
	ul{
		margin:0;
		padding:0;
		&.sid{
			margin:0;
			padding:0;		
		}
	}
	p{
		font-size:12px;
	}
	a{
		color:$dark_blue;
		&:hover{
			color:$lightred;
		}
	}
	.widget-container {
		padding:15px 17px 5px;
		background-color:#fff;
		border-top:3px solid $dark_blue;
	}
	.bluebg{
		background-color:$dark_blue;
		color:#fff;
		border-top:3px solid $darkest_blue;
		h3{
			font-size:18px;
			color:#fff;
		}
		a{
			color:#fff;
		}
		.alignleft{
			padding-bottom:80px;
		}
		
	}
	.widget_flexipages{
		font-family:$heading_font;
		text-transform:uppercase;
		font-size:13px;
		font-weight:700;
		li{
			margin-bottom:5px;
		}
		a{
			&:before{
			    content: "\f005";
			    font-family: FontAwesome;
			    font-style: normal;
			    font-weight: normal;
			    text-decoration: inherit;
			    font-size: 10px;
			    padding-right: 0.5em;
			    top: 10px;
			    left: 0;
			}
		}
		h3{
			color:$lightred;
			font-size:18px;
		}
		.current_page_item{
			a{
				color:$lightred;
			}
		}
	}
	.widget_categories{
		@extend .widget_flexipages;
		
	}
	.widget_recent_entries{
		@extend .widget_flexipages;
		
	}

}

.page-template-page-calendar-php{
	#main-content{
		.vcalendar{
			a{
				background-color:transparent !important;
			}
		}
		.tribe-events-button{
			background-color:$dark_blue;	
		}
		.tribe-events-sub-nav{
			a{
				background-color:transparent;
			}
		}
		.tribe-events-calendar td.tribe-events-present div[id*=tribe-events-daynum-], .tribe-events-calendar td.tribe-events-present div[id*=tribe-events-daynum-]>a{
			background-color:$dark_blue;			
		}

	}
}


/* Above Footer Rule */

.bottom-rule-home{
	padding-top:100px;
	height:16px;
	background-image:url(../images/rule_footer.jpg);
	background-size:1300px 16px;
	background-position: bottom center;
	background-repeat:no-repeat;
}

.bottom-rule{
	padding-top:100px;
	border-bottom:16px solid $darkest_blue;
}

/* Footer */

.footer{
	padding: 40px 0;
	color:#fff;
	a{
	}
	#menu-footer-menu{
		margin:0;
		padding:0;
/*
		background-image:url(../images/logo_ICA_state.png);
		background-position: left 4px;
		background-repeat: no-repeat;
*/
		
		a{
			text-transform: uppercase;
		}
	}

	.menu{
		float:left;
		li{
			margin:0;
			padding:0;
		}
		a{
			font-family:$heading_font;
			font-size:12px;
		}
	}
	
	.footer-social{
		text-align:right;
		li{
			display:inline;
			padding-left:5px;
			a{
				font-size:22px;
				color:$lightpalecyan;
				&:hover{
					color:$lightred
				}
			}
		}
	}
	.footer-copyright{
		p{
			margin:0;
			font-size:10px;
			line-height:14px;
		}
	}
	
	
}

/* Fade In */
@-webkit-keyframes fadeIn { from { opacity:0;} to { opacity:1;} }
@-moz-keyframes fadeIn { from { opacity:0;} to { opacity:1;} }
@keyframes fadeIn { from { opacity:0;} to { opacity:1;} }

/* Fade in up */

@-webkit-keyframes fadeInUp { from { opacity:0; top:5px} to { opacity:1; top:0} }
@-moz-keyframes fadeInUp { from { opacity:0; top:5px} to { opacity:1; top:0} }
@keyframes fadeInUp { from { opacity:0; top:5px} to { opacity:1; top:0} }



.fade-in {
	opacity:0;  /* make things invisible upon start */
	-webkit-animation:fadeIn ease-in 1;  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
	-moz-animation:fadeIn ease-in 1;
	animation:fadeIn ease-in 1;

	-webkit-animation-fill-mode:forwards;  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
	-moz-animation-fill-mode:forwards;
	animation-fill-mode:forwards;

	-webkit-animation-duration:.5s;
	-moz-animation-duration:.5s;
	animation-duration:.5s;
}

.fade-in-up{
	opacity:0;  /* make things invisible upon start */
	top:5px;
	-webkit-animation:fadeIn ease-in 1;
	-moz-animation:fadeIn ease-in 1;
	animation:fadeIn ease-in 1;

	-webkit-animation-fill-mode:forwards;  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
	-moz-animation-fill-mode:forwards;
	animation-fill-mode:forwards;

	-webkit-animation-duration:.5s;
	-moz-animation-duration:.5s;
	animation-duration:.5s;
}

.fade-in{
	&.noticable{
		-webkit-animation-delay: 0.1s;
		-moz-animation-delay: 0.1s;
		animation-delay: 0.1s;
	}
	&.elements{
		-webkit-animation-delay: .2s;
		-moz-animation-delay:.2s;
		animation-delay: .2s;
	}
	&.secondary{
		-webkit-animation-delay: .3s;
		-moz-animation-delay: .3s;
		animation-delay: .3s;
	}
}

.fade-in-up{ 
	@extend .fade-in;
}